@import "primeng/resources/themes/lara-dark-teal/theme.css";

a.active-link {
  background-color: var(--primary-color) !important;
  color: black !important;
}
a.active-link i {
  color: black !important;
}
a.active-link:hover {
  background-color: var(--primary-color) !important;
  color: black !important;
}
body {
  background: #000000;
}
